import { Col, Div, Row, Text } from "atomize"
import React from "react"

export const termsConditions = (
    <Div p={{ t: "3rem" }}>
        <Text textSize="title" textColor="accent_dark">
            Da fare
        </Text>
    </Div>
)

const Tr = props => {
    return (
        <Row p={{ y: "1rem" }}>
            <Col size={{ xs: "4", md: "3" }}>
                <Text textSize="title" textColor="accent" textWeight="bold">
                    {props.th}
                </Text>
            </Col>
            <Col size={{ xs: "8", md: "9" }}>
                <Text textSize="subheader" textColor="accent_dark">
                    {props.td}
                </Text>
            </Col>
        </Row>
    )
}

export const legalNotice = (
    <Div p={{ t: "0.5rem" }}>
        <Tr th="Data costituzione" td="21/10/2019" />
        <Tr th="Comune" td="BOLOGNA (BO)" />
        <Tr th="Impresa costituita" td="IN AUTONOMIA DALL'IMPRENDITORE (ART. 24)" />
        <Tr th="Sede legale" td="VIA SAN PETRONIO VECCHIO, 48/2 40125 BOLOGNA (BO)" />
        <Tr
            th="Oggetto sociale"
            td="LA SOCIETA' HA PER OGGETTO LO SVILUPPO, LA PRODUZIONE E LA COMMERCIALIZZAZIONE DI PRODOTTI O SERVIZI INNOVATIVI AD ALTO VALORE TECNOLOGICO, E PIU' SPECIFICAMENTE IN VIA PREVALENTE LO SVILUPPO, LA PRODUZIONE E LA COMMERCIALIZZAZIONE DI UN INNOVATIVO SERVIZIO PER LA VENDITA DI PRODOTTI ALIMENTARI INVENDUTI A FINE GIORNATA, ATTRAVERSO UNA PIATTAFOMA ONLINE/APPLICAZIONE MOBILE TALE SERVIZIO E' INNOVATIVO IN QUANTO PERMETTE DI SCEGLIERE I PRODOTTI NELLO SPECIFICO, ED E' AD ALTO VALORE TECNOLOGICO IN QUANTO LA PIATTAFORMA VERRA' IMPLEMENTATA ATTRAVERSO ALGORITMI PROPRIETARI E TECNICHE INFORMATICHE ALL'AVANGUARDIA."
        />
        <Tr
            th="Breve descrizione"
            td="SERVIZIO PER LA VENDITA DI ECCEDENZE ALIMENTARI, CHE INCENTIVA AGLI ESERCENTI UN RITORNO MONETARIO E GARANTISCE AI CONSUMATORI UN PASTO DI ALTA QUALITÀ A PREZZI VANTAGGIOSI CON LA COMODITÀ DELLA CONSEGNA A CASA, TUTELANDO L'AMBIENTE ATTRAVERSO UNA POLITICA DI ECONOMIA CIRCOLARE CHE PERSEGUE GLI OBBIETTIVI DELL'AGENDA 2030 DELLE NAZIONI UNITE."
        />
        <Tr
            th="Attività spese ricerca e sviluppo"
            td="PER IL PRIMO ESERCIZIO SI STIMANO SPESE IN RICERCA E SVILUPPO PARI A 10.000,00 EURO A FRONTE DI UN MAGGIOR VALORE TRA COSTI E VALORE TOTALE DELLA PRODUZIONE DI 39.000,00 EURO. QUESTO COMPORTA CHE LE SPESE IN RICERCA E SVILUPPO SIANO IL 25,64% RISPETTO AL 15% RICHIESTO DEL MAGGIOR VALORE TRA COSTI E VALORE TOTALE DELLA PRODUZIONE. IN PARTICOLARE, L'ATTIVITA' SPECIFICA CUI FANNO CAPO I REQUISITI RELATIVI ALL'INNOVAZIONE TECNOLOGICA E LE SPESE IN RICERCA E SVILUPPO CONSISTE NELLA PRODUZIONE DI UNA APPLICAZIONE MOBILE E DI UNA PIATTAFORMA WEB PER L'INSERIMENTO DELLE DISPONIBILITA' DEL RISTORATORE."
        />
        <Tr
            th="Titoli ed esperienze professionali soci"
            td="GABRIELE CALAROTA (SOCIO) DIPLOMA DI LAUREA TRIENNALE IN INFORMATICA CON VOTO 110/110 E LODE E LAUREANDO AL CORSO DI LAUREA MAGISTRALE IN INFORMATICA PRESSO L'UNIVERSITA' DI BOLOGNA (LAUREA PREVISTA AD AUTUNNO 2020) LUCA MOROSINI (SOCIO) TITOLI DI STUDIO: LAUREA TRIENNALE IN SCIENZE INFORMATICHE CONSEGUITA PRESSO L'UNIVERSITA' DI BOLOGNA NEL 2019 DIPLOMA IN TECNICO INFORMATICO CONSEGUITO PRESSO L'IIS ALDINI VALERIANI NEL 2015 ESPERIENZE PROFESSIONALI: DAL 2014: UN MESE DI STAGE PRESSO SOFTWEB SRL. DAL 2018 A OGGI: ISTRUTTORE DI TENNIS. OSSAMA GANA (SOCIO) TITOLO DI STUDIO: LAUREA TRIENNALE INFORMATICA CONSEGUITA PRESSO L'ALMA MATER STUDIORUM - UNIVERSITA' DI BOLOGNA. DIPLOMA TECNICO COMMERCIALE SISTEMI INFORMATIVI AZIENDALI CONSEGUITO PRESSO L'I.T.C ROSA LUXEMBURG DI BOLOGNA. ALBERTO DRUSIANI (SOCIO E AMMINISTRATORE) LAUREATO TRIENNALE IN INFORMATICA A BOLOGNA."
        />
        <Tr th="Relazioni con incubatore" td="INCUBATA DA ALMACUBE S.R.L. E NANA BIANCA S.R.L." />
        <Tr th="Ultimo bilancio depositato" td="-" />
        <Tr th="Elenco diritti di privativa	" td="-" />
    </Div>
)
